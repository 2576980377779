import { routeToUrl } from "@app-routes";
import { FeaturesGridItem } from "@components/LandingPage/FeaturesGridItem";
import { RegisterItem } from "@components/LandingPage/HomePage/RegisterItem";
import { videoPoster } from "@components/LandingPage/NewVoteOnline/styles/videoPoster";
import { VoteBenefitBox } from "@components/LandingPage/VoteBenefitBox";
import { VoteBenefitScreenshot } from "@components/LandingPage/VoteBenefitScreenshot";
import { config } from "@config/config";
import { PATRONS_DATA } from "@config/landingPageData";
import { useMeNullable } from "@profile-zone/hooks/use-me-nullable";
import { AppInstance } from "@shared/utils/app-instance";
import { Trans } from "@translations/trans";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { Button } from "@ui/Button";
import { Container, LandingPageContainer } from "@ui/Container";
import { FlexBox } from "@ui/FlexBox";
import { Grid } from "@ui/Grid";
import { LandingScreen } from "@ui/Layout/LandingScreen/LandingScreen";
import { Link } from "@ui/Link";
import { Patrons } from "@ui/Patrons/Patrons";
import { TextH1, TextH4, TextLarge } from "@ui/Text";
import { YouTubeVideo } from "@ui/YouTubeVideo";
import { Hide } from "@uxf/core-react/components/hide";
import { isNotNil } from "@uxf/core/utils/is-not-nil";
import { spacing } from "@uxf/styles/units/spacing";
import { NextPage } from "next";
import { rem } from "polished";
import { useTheme } from "styled-components";

const NewOnlineVote: NextPage = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const me = useMeNullable();
    const isUserLogged = isNotNil(me);

    return (
        <LandingScreen
            fixedNavBar={true}
            ogData={{
                ogTitle: t("page-landing-new-online-vote:og.title"),
                description: t("page-landing-new-online-vote:og.description"),
                image: "/static/images/landingPage/man-with-laptop.jpg",
            }}
            pageName="home"
            title={t("page-landing-new-online-vote:title")}
        >
            <LandingPageContainer as="section" mt={[spacing(5), null, spacing(7)]}>
                <FlexBox alignItems="center" flexDirection="column" mb={[spacing(1), spacing(3), null, spacing(5)]}>
                    <TextH1
                        mb={spacing(2.5)}
                        mt={spacing(4)}
                        textAlign="center"
                        textFontWeight="semiBold"
                        textVariant={["h2", null, "h1"]}
                    >
                        {t("page-landing-new-online-vote:h1")}
                    </TextH1>
                    <Box maxWidth={640} mb={spacing(5)}>
                        <TextH4 textVariant={["h5", null, "h4"]} textFontWeight="regular" textAlign="center">
                            {t("page-landing-new-online-vote:h4")}
                        </TextH4>
                    </Box>
                    <Button
                        className="button"
                        href={
                            isUserLogged
                                ? routeToUrl("house-zone/house-community")
                                : routeToUrl("auth-zone/registration", {})
                        }
                        shadow
                        size="huge"
                        variant="primary"
                        width={270}
                    >
                        {t("page-landing-new-online-vote:btn1")}
                    </Button>
                </FlexBox>
            </LandingPageContainer>
            <Box mt={220}>
                <Container boxPosition="relative" maxWidth={[540, 720, 960, 1020, 1440]} pl={0} pr={0}>
                    <FlexBox justifyContent="center" style={{ top: rem(-180) }} boxPosition="absolute">
                        <FlexBox
                            boxShadow={theme.shadow.dropdown}
                            maxWidth={[360, 420, 640, 780, 900]}
                            mr={spacing(2)}
                            ml={spacing(2)}
                            childrenFlexProps={{ maxWidth: [360, 420, 640, 780, 900] }}
                        >
                            <Box
                                $css={videoPoster(theme)}
                                borderRadius="default"
                                boxShadow={theme.shadow.dropdown}
                                overFlow="hidden"
                            >
                                <YouTubeVideo videoId="_osfgBaSlMU" />
                            </Box>
                        </FlexBox>
                    </FlexBox>
                </Container>
                <Box
                    as="section"
                    pb={spacing(8)}
                    pl={[spacing(2)]}
                    pr={[spacing(2)]}
                    pt={[spacing(2), 90, 260, 380, 470]}
                    backgroundColor="primaryBg"
                >
                    <LandingPageContainer>
                        <Grid gridColumnCount={[1, null, null, 3]} gridGap={spacing(4)} mt={spacing(4)}>
                            <FeaturesGridItem
                                icon="infoCircle"
                                large
                                title={<>{t("page-landing-new-online-vote:features.1.title")}</>}
                                text={
                                    <Trans
                                        i18nKey="page-landing-new-online-vote:features.1.text"
                                        components={{
                                            link: (
                                                <Link
                                                    href="https://poradna.sousede.cz/m%C5%AF%C5%BEu-u-v%C3%A1s-vytvo%C5%99it-hlasov%C3%A1n%C3%AD-pro-d%C5%AFm-kter%C3%A9-bude-pr%C3%A1vn%C4%9B-uznateln%C3%A9"
                                                    target="_blank"
                                                    textColor="primary"
                                                    underline
                                                />
                                            ),
                                        }}
                                    />
                                }
                            />
                            <FeaturesGridItem
                                icon="mail"
                                large
                                title={<>{t("page-landing-new-online-vote:features.2.title")}</>}
                                text={<>{t("page-landing-new-online-vote:features.2.text")}</>}
                            />
                            <FeaturesGridItem
                                icon="rocket"
                                large
                                title={<>{t("page-landing-new-online-vote:features.3.title")}</>}
                                text={<>{t("page-landing-new-online-vote:features.3.text")}</>}
                            />
                        </Grid>
                    </LandingPageContainer>
                </Box>
            </Box>
            <Container mb={[spacing(5), null, spacing(10)]}>
                <FlexBox flexDirection={["column", null, "row"]} mt={[spacing(5), null, spacing(8)]}>
                    <Box>
                        <FlexBox justifyContent={["center", null, "flex-start"]}>
                            <VoteBenefitScreenshot
                                imageSrc="/static/images/landingPage/vote-voting.jpg"
                                imgMaxHeight={[260, null, 210, 280, 300]}
                            />
                        </FlexBox>
                        <Box mt={spacing(4)}>
                            <VoteBenefitBox
                                heading={t("page-landing-new-online-vote:benefits.1.heading")}
                                title={<>{t("page-landing-new-online-vote:benefits.1.title")}</>}
                                text={<>{t("page-landing-new-online-vote:benefits.1.text")}</>}
                            />
                        </Box>
                    </Box>
                    <Box ml={[null, null, spacing(4)]} mt={[spacing(4), null, 0]}>
                        <FlexBox justifyContent={["center", null, "flex-start"]}>
                            <VoteBenefitScreenshot
                                imageSrc="/static/images/landingPage/vote-result.jpg"
                                imgMaxHeight={[260, null, 210, 280, 300]}
                            />
                        </FlexBox>
                        <Box mt={spacing(4)}>
                            <VoteBenefitBox
                                heading={t("page-landing-new-online-vote:benefits.2.heading")}
                                title={<>{t("page-landing-new-online-vote:benefits.2.title")}</>}
                                text={<>{t("page-landing-new-online-vote:benefits.2.text")}</>}
                            />
                        </Box>
                    </Box>
                </FlexBox>
            </Container>
            <Hide when={AppInstance.isSlovak}>
                <Patrons data={[PATRONS_DATA.patron22, PATRONS_DATA.patron23, PATRONS_DATA.patron24]} />
            </Hide>
            <LandingPageContainer mt={[spacing(8), null, null, spacing(12)]} mb={spacing(4)}>
                <RegisterItem
                    subtitle={
                        <>
                            <Button
                                ml="auto"
                                mr="auto"
                                pl={48}
                                pr={48}
                                href={
                                    isUserLogged
                                        ? routeToUrl("house-zone/house-community")
                                        : routeToUrl("auth-zone/registration", {})
                                }
                                size="huge"
                                variant="primary"
                            >
                                {t("page-landing-new-online-vote:register.btn")}
                            </Button>
                            <TextLarge mt={spacing(4)}>
                                <Trans
                                    i18nKey="page-landing-new-online-vote:register.text1"
                                    components={{
                                        link: (
                                            <Link
                                                href={`mailto:${config.TRANSLATION_BUSINESS_EMAIL}`}
                                                textColor="primary"
                                                textFontWeight="medium"
                                            />
                                        ),
                                    }}
                                />
                            </TextLarge>
                        </>
                    }
                    title={t("page-landing-new-online-vote:register.title")}
                />
            </LandingPageContainer>
        </LandingScreen>
    );
};

export default NewOnlineVote;
